import general from "general";
import * as leaflet from "general/components/leafletAssets";

const mapObject = null;

async function setupMap() {
    let mapBoxAccessToken =
        document.querySelector("#page-data").dataset.mapBoxAccessToken;
    let coord = [-28, 23];
    const mapObject = L.map("results__provider__map", {
        maxZoom: 17,
        zoomControl: false,
    }).setView(coord, 5);

    L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        {
            tileSize: 512,
            maxZoom: 18,
            zoomOffset: -1,
            id: "mapbox/streets-v11",
            accessToken: mapBoxAccessToken,
        },
    ).addTo(mapObject);

    L.control
        .zoom({
            position: "topright",
        })
        .addTo(mapObject);

    L.control
        .attribution({
            prefix: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
            position: "bottomleft",
        })
        .addTo(mapObject);

    return mapObject;
}

const toggleMap = () => {
    if (window.innerWidth > 700) {
        leaflet.assets({
            onLoad: async function () {
                let instanceName =
                    document.querySelector("#page-data").dataset.instanceName;
                let path = general.getStaticPath(
                    `images/${instanceName}/general/marker.png`,
                );
                let darkPath = general.getStaticPath(
                    `images/${instanceName}/general/marker-dark.png`,
                );

                const mapObject = await setupMap();

                const workplaceMarkers = {};
                document
                    .querySelectorAll(".card__provider[data-is-physical=true]")
                    .forEach((providerEL) => {
                        if (
                            providerEL.dataset.workplaceId in
                                workplaceMarkers ==
                            false
                        ) {
                            let latitude = parseFloat(providerEL.dataset.lat);
                            let longitude = parseFloat(providerEL.dataset.lon);

                            workplaceMarkers[providerEL.dataset.workplaceId] = {
                                point: new L.LatLng(latitude, longitude),
                                marker: new L.Marker([latitude, longitude], {
                                    icon: L.divIcon({
                                        popupAnchor: [0, -34],
                                        iconAnchor: [12, 34],
                                        iconSize: [23, 34],
                                        html: `<img data-number="${
                                            providerEL.dataset.workplaceId
                                        }" src="${path}"><span>${
                                            Object.keys(workplaceMarkers)
                                                .length + 1
                                        }</span>`,
                                    }),
                                }),
                            };
                        }

                        providerEL.addEventListener("mouseenter", (e) => {
                            let marker = document.querySelector(
                                `img[data-number="${providerEL.dataset.workplaceId}"]`,
                            );
                            marker.setAttribute("src", darkPath);
                        });
                        providerEL.addEventListener("mouseleave", (e) => {
                            let marker = document.querySelector(
                                `img[data-number="${providerEL.dataset.workplaceId}"]`,
                            );
                            marker.setAttribute("src", path);
                        });
                    });

                const points = Object.entries(workplaceMarkers).reduce(
                    (acc, [, { point, marker }]) => {
                        acc.push(point);
                        marker.addTo(mapObject);
                        return acc;
                    },
                    [],
                );

                // Add the users location pin if doing a geo search
                const mapDataset = document.querySelector(
                    "#results__provider__map",
                ).dataset;
                if ("geoSearch" in mapDataset) {
                    const [lat, lng] = mapDataset.coords.split("|");
                    let latitude = parseFloat(lat);
                    let longitude = parseFloat(lng);
                    points.push(new L.LatLng(latitude, longitude));
                    let coord = [latitude, longitude];
                    let marker = new L.Marker(coord, {
                        title: mapDataset.label,
                    }).addTo(mapObject);
                }

                if (points.length > 0) {
                    mapObject.fitBounds(new L.LatLngBounds(points), {
                        padding: [35, 35],
                    });
                }
            },
        })(document);
    }
    return true;
};

export const setup = () => {
    toggleMap() && window.addEventListener("resize", toggleMap);
};
