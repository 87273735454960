class Asset {
    section;
    tag;
    attributes;

    constructor(section, tag, attributes) {
        this.section = section;
        this.tag = tag;
        this.attributes = attributes;
    }

    insert(document) {
        const _element = document.createElement(this.tag);
        let { onload, ...attributes } = this.attributes;
        if (onload) {
            _element.onload = function() {
                onload();
            };
        }
        for (const prop in attributes) {
            _element.setAttribute(prop, attributes[prop]);
        }
        document[this.section].appendChild(_element);
    }
}


const assets = ({ onLoad }) => {
    const leafleftAssets = [
        new Asset("head", "link", {
            rel: "stylesheet",
            href: "https://unpkg.com/leaflet@1.3.3/dist/leaflet.css",
        }),
        new Asset("head", "link", {
            rel: "stylesheet",
            href: `${globalConfig.staticRoot}/css/recomed/partials/map-leaflet.css`,
        }),
        new Asset("head", "script", {
            type: "text/javascript",
            src: "https://unpkg.com/leaflet@1.4.0/dist/leaflet.js",
            onload: onLoad,
        }),
        new Asset("body", "span", { "data-leaflet-hint": "true" }),
    ];

    return (document) => {
        if (document.querySelector("span[data-leaflet-hint='true']") == null) {
            leafleftAssets.forEach((asset) => asset.insert(document));
        }
    };
};

export { assets }
