/**
 * Created by adamj on 11/13/14.
 */

import template from "lodash/template";
import each from "lodash/each";

import constants from "general/constants";

var templateOptions = {
        imports: {
            each: each
        }
    },
    breadcrumbsContainer = null,
    breadcrumbsList = null,
    breadcrumbsContent = null,
    breadcrumbListingItemTemplate = null,
    breadcrumbListTemplate = null,
    breadcrumbContentTitleTemplate = null,
    breadcrumbContentItemTemplate = null,
    breadcrumbContentTemplate = null,
    exports = {
         /**
         * Returns an Object containing parameters for existing selected breadcrumbs.
         *
         * @returns {Object}
         */
        selected: function () {
            var data = {};
            breadcrumbsContent.find("a.breadcrumbs-content__link.is-active").each(function () {
                var contentLink = $(this);
                data[contentLink.data("parameter")] = contentLink.data("value");
            });
            return data;
        },

        /**
         * Binds click events to breadcrumbs anchor items
         */
        click: function (eventHandler) {
            breadcrumbsContent.find("a.breadcrumbs-content__link").click(eventHandler);
        },

        /**
         * Renders the breadcrumbs using supplied data
         *
         * @param {Array} breadcrumbs
         * @param {Function} [eventHandler]
         */
        render: function (breadcrumbs, eventHandler) {
            breadcrumbsList.empty().append(breadcrumbListTemplate({
                breadcrumbListingItemTemplate: breadcrumbListingItemTemplate,
                breadcrumbs: breadcrumbs
            }));
            breadcrumbsContent.empty().append(breadcrumbContentTemplate({
                breadcrumbContentTitleTemplate: breadcrumbContentTitleTemplate,
                breadcrumbContentItemTemplate: breadcrumbContentItemTemplate,
                breadcrumbs: breadcrumbs
            }));
            exports.setup();
            if (eventHandler) exports.click(eventHandler);
        },
        setup: function () {
            var breadcrumbsItem = $('.js-breadcrumbs-item'),
                breadcrumbsContentItem = $('.js-breadcrumbs-content-item');

            breadcrumbsItem.click(function(){
                var $this = $(this),
                    index = $this.index();

                $this.toggleClass(constants.activeClass).siblings().removeClass(constants.activeClass);
                breadcrumbsContentItem.eq(index).toggleClass(constants.activeClass).siblings().removeClass(constants.activeClass);
            });
        }
};
$(document).ready(function () {
    breadcrumbsContainer = $("#breadcrumbs-container");
    breadcrumbsList = breadcrumbsContainer.find("ul.breadcrumbs-listing");
    breadcrumbsContent = breadcrumbsContainer.find("ul.breadcrumbs-content-listing");
    breadcrumbListingItemTemplate = template($("#breadcrumb-listing-item-template").html() || "", templateOptions);
    breadcrumbListTemplate = template($("#breadcrumb-listing-template").html() || "", templateOptions);
    breadcrumbContentTitleTemplate = template($("#breadcrumb-content-title-template").html() || "", templateOptions);
    breadcrumbContentItemTemplate = template($("#breadcrumb-content-item-template").html() || "", templateOptions);
    breadcrumbContentTemplate = template($("#breadcrumb-content-listing-template").html() || "", templateOptions);
});

export default exports;
