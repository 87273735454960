import React from "react";
import ReactDOM from "react-dom";
import general from "general";
import constants from "general/constants";
import breadcrumbs from "general/components/breadcrumbs";
import "general/components/header";
import "general/components/footer";
import { LocationSearch } from "components/LocationSearch";
import * as MapResult from "components/MapResult";


document.addEventListener("DOMContentLoaded", function (event) {
  const lsForms = document.querySelectorAll("[data-react__location-search]");
  if (lsForms.length > 0) {
    const render = (forms, props) => {
      forms.forEach((el) => {
        ReactDOM.render(
          <React.StrictMode>
            <LocationSearch {...props} />
          </React.StrictMode>,
          el
        );
        general.attachSpinnerEvent(el);
      });
    };

    const variants = JSON.parse(
      document.querySelector("[data-location-search-form-variants]").dataset
        .locationSearchFormVariants
    );
    const props = JSON.parse(
      document.querySelector("[data-location-search-form-props]").dataset
        .locationSearchFormProps
    );
    render(lsForms, {
      variant: props.variant,
      location: props.location,
      whatSelected: props.what.selected,
      variantSettings: JSON.stringify(variants[props.variant]),
      whatList: lsForms[0].dataset.whatList,
      whatPlaceholder: lsForms[0].dataset.whatPlaceholder,
      showReasonTypes: props.reason_type.show,
      defaultRadius: props.default_radius,
      reasonType: props.reason_type.q
    });
  }

  function breadcrumbs() {
    var breadcrumbsItem = $(".js-breadcrumbs-item"),
      breadcrumbsContentItem = $(".js-breadcrumbs-content-item");

    breadcrumbsItem.click(function () {
      var $this = $(this),
        index = $this.index();

      $this
        .toggleClass(constants.activeClass)
        .siblings()
        .removeClass(constants.activeClass);
      breadcrumbsContentItem
        .eq(index)
        .toggleClass(constants.activeClass)
        .siblings()
        .removeClass(constants.activeClass);
    });
  }

  general.attachSpinnerEvent(document.querySelector("#results__provider__sidebar"));

  breadcrumbs();

  MapResult.setup();

  sendResultAnalytics();

  let providerListEl = document.querySelector("#results__provider__sidebar");
  if (providerListEl) {
    providerListEl.addEventListener("click", function (event) {
      let clickSourceEl = event.target.closest("[data-click-source]");
      if (clickSourceEl) {
        let cardProviderEl = event.target.closest(".card__provider");
        navigator.sendBeacon(
          "/events",
          JSON.stringify([
            {
              type: "search-results__selection",
              datetime_captured: new Date().toISOString(),
              path: window.location.pathname,
              time: performance.now(),
              data: {
                source: clickSourceEl.dataset.clickSource,
                index: cardProviderEl.dataset.index,
                id: cardProviderEl.dataset.id,
                listing: cardProviderEl.dataset.listing,
              },
            },
          ]),
        );
      }
    });
  }
});


function sendResultAnalytics() {
  let searchMetadata = document.querySelector('[data-search-metadata]').dataset;
  let events = [];
  document.querySelectorAll(".card__provider").forEach((providerEL, index) => {
    events.push({
      v: 1,
      t: 'event',
      ec: 'Result Displayed',
      ea: searchMetadata.searchTerm,
      el: providerEL.dataset.id,
      cd1: parseInt(searchMetadata.page),
      cd2: index + 1, // position
    })
  });
  general.postGABatchHits(events);
}
